.responsive-table {
    display: block;
    width: 100%;
    overflow-x: auto;}

.orders-table {
    border-bottom: 1px#E4E7EB solid;
    font-size: 14px;
    min-height: 75px;
    padding-bottom: 10px;
    /* margin-bottom: 10px; */
}

.orders-table-header {
    border-bottom: 1px#E4E7EB solid;
    border-top: 1px#E4E7EB solid;
    font-size: 16px;
    font-weight: bold;
    /* margin-bottom: 10px; */
}

.orderID {
    color: #425A70;
    text-decoration: underline;
}

.opacity-test {
    opacity: 0.6;
    filter: blur(1px);

  }

  .spinner-table {
    display: block;
    position: fixed;
    z-index: 1031; /* High z-index so it is on top of the page */
    top: 50%;
    right: 50%; /* or: left: 50%; */
  }  

  .fixedWidthImage {
    width: 60px;
    
 } 

 .order-fees {
    color: #dc3545;
    font-size: 11px;
  }


  .fixedWidthBox {
    width: 20px;
    padding-left: 5px;
}

.transaction-items {
  list-style: none;
  font-size: 12px;
  padding-inline-start: 0px;
}