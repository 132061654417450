.graph-container {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
   
    /* padding: 10px; */
    /* max-width: 1000px;
    max-height: 600px;
    background-color: #fff; */
  }

  /* .card {
    max-width: '10rem' 
  } */

  .mobile-cards{
    margin-top: 10px;
  }

  .center-title{
    margin-bottom: 0;
    padding-top: 5px;
  }