.list {
    height: calc(100vh - 150px)
    }


.sku-badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.btn-sku {
    
    color: #fff;
    background-color:rgba(36, 99, 188, 1);
    border-color:rgba(36, 99, 188, 1);
    display: inline-block;
    padding: .25em .4em;
    font-size: 11px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-sku:active {

}

.btn-sku:hover {
    color: #fff;
    background-color: rgba(20, 73, 149, 1)	;
    border-color: rgba(20, 73, 149, 1)	;
}

.btn-sku:focus{
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 180 255 / 25%);
}

.btn-time {
    
    color: #fff;
    background-color: #878585;
    border-color: #878585;
    display: inline-block;
    padding: .25em .4em;
    font-size: 11px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-tsin {
    
    color: #fff;
    background-color: rgba(22, 166, 121, 1)	;
    border-color: rgba(22, 166, 121, 1)	;
    display: inline-block;
    padding: .25em .4em;
    font-size: 11px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-tsin:active {

}

.btn-tsin:hover {
    color: #fff;
    background-color: rgba(0, 122, 92, 1)	;
    border-color: rgba(0, 122, 92, 1)	;
}

.btn-tsin:focus{
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 180 255 / 25%);
}

.btn-repricing {
    
    color: #277aa0;
    background-color: #fcfcfc;
    border-color: #277aa0;
    display: inline-block;
    padding: .25em .4em;
    font-size: 11px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-repricing-1 {
    
    color: #fff;
    background-color: #277aa0;
    border-color: #277aa0;
    display: inline-block;
    padding: .25em .4em;
    font-size: 11px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}



.btn-repricing.active {
    color: #fff;
    background-color: #277aa0;
    border-color: #277aa0;
    display: inline-block;
    padding: .25em .4em;
    font-size: 11px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

}

.btn-repricing:hover {
    color: #fff;
    background-color: #3196c5;
    border-color: #3196c5;
}

.btn-repricing:focus{
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 180 255 / 25%);

}